// @flow
import type { Styles } from './types';
import {
  reducePaddingFactorStyles,
  getBackgroundColor,
  getBackgroundGradientDirection,
  getBackgroundColorCSSValue,
  getBackgroundGradientCSSValue,
} from './helpers/theme';

const styles: Styles = theme => ({
  root: ({
    paddingFactor,
    backgroundColor = getBackgroundColor(theme),
    backgroundGradientDirection = getBackgroundGradientDirection(theme),
  }) => {
    const cssColor = getBackgroundColorCSSValue(theme, backgroundColor);
    const cssGradient = getBackgroundGradientCSSValue(
      theme,
      backgroundColor,
      backgroundGradientDirection,
    );

    return {
      width: '100%',
      backgroundColor: cssColor,
      backgroundImage: cssGradient,
      ...reducePaddingFactorStyles(theme, paddingFactor),
    };
  },
});

export default styles;
