// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import MuiContainer from '@material-ui/core/Container';
import classNames from 'classnames';

import styles from './styles';
import type { Props } from './types';

const BodySection = ({
  classes,
  className,
  children,
  paddingFactor,
  backgroundColor,
  backgroundGradientDirection,
  Container,
  ContainerProps,
  component,
  ...props
}: Props) => {
  const Component = component || 'div';
  return (
    <Component className={classNames(classes.root, className)} {...props}>
      {Container ? (
        <Container {...ContainerProps}>{children}</Container>
      ) : (
        children
      )}
    </Component>
  );
};

BodySection.defaultProps = {
  className: undefined,
  paddingFactor: undefined,
  backgroundColor: undefined,
  backgroundGradientDirection: undefined,
  Container: MuiContainer,
  ContainerProps: undefined,
  component: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'BodySection' })(BodySection);
