// @flow
import type { GradientDirectionOrDegree } from '../types';

export default function getGradientCSSDirection(
  gradientDirection: ?GradientDirectionOrDegree,
): string | void {
  switch (typeof gradientDirection) {
    case 'string':
      return gradientDirection;
    case 'number':
      return `${gradientDirection}deg`;
    default:
      return undefined;
  }
}
