// @flow
import type { GradientDirectionOrDegree, GradientColorStops } from '../types';
import getGradientCSSDirection from './getGradientCSSDirection';

export type Config = {|
  colorStops: ?GradientColorStops,
  direction: ?GradientDirectionOrDegree,
|};

export default function getGradientCSSGradient({
  colorStops,
  direction,
}: Config) {
  const cssDirection = getGradientCSSDirection(direction);
  const colorStops$length = colorStops && colorStops.length;

  return colorStops && colorStops$length && cssDirection
    ? `linear-gradient(${cssDirection}, ${colorStops
        .map(
          (colorStop, index) =>
            `${colorStop} ${(index / (colorStops$length - 1)) * 100}%`,
        )
        .join(', ')})`
    : undefined;
}
